var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"登录"}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":"账户或密码错误"}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {rules: [{ required: true, message: '请输入帐户名或邮箱地址' }, { validator: _vm.handleUsernameOrEmail }], validateTrigger: 'change'}
            ]),expression:"[\n              'username',\n              {rules: [{ required: true, message: '请输入帐户名或邮箱地址' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}\n            ]"}],attrs:{"size":"large","type":"text","placeholder":"账户"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
            ]),expression:"[\n              'password',\n              {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}\n            ]"}],attrs:{"size":"large","placeholder":"密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }